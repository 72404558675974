/* ant design reset.css - begin */

/* stylelint-disable */
html,
body {
    width: 100%;
    height: 100%;
}
input::-ms-clear,
input::-ms-reveal {
    display: none;
}
*,
*::before,
*::after {
    box-sizing: border-box;
}
html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@-ms-viewport {
    width: device-width;
}
body {
    margin: 0;
}
[tabindex='-1']:focus {
    outline: none;
}
hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0.5em;
    font-weight: 500;
}
p {
    margin-top: 0;
    margin-bottom: 1em;
}
abbr[title],
abbr[data-original-title] {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline;
    text-decoration: underline dotted;
    border-bottom: 0;
    cursor: help;
}
address {
    margin-bottom: 1em;
    font-style: normal;
    line-height: inherit;
}
input[type='text'],
input[type='password'],
input[type='number'],
textarea {
    -webkit-appearance: none;
}
ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 1em;
}
ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0;
}
dt {
    font-weight: 500;
}
dd {
    margin-bottom: 0.5em;
    margin-left: 0;
}
blockquote {
    margin: 0 0 1em;
}
dfn {
    font-style: italic;
}
b,
strong {
    font-weight: bolder;
}
small {
    font-size: 80%;
}
sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}
sub {
    bottom: -0.25em;
}
sup {
    top: -0.5em;
}
pre,
code,
kbd,
samp {
    font-size: 1em;
    font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
}
pre {
    margin-top: 0;
    margin-bottom: 1em;
    overflow: auto;
}
figure {
    margin: 0 0 1em;
}
img {
    vertical-align: middle;
    border-style: none;
}
a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
    touch-action: manipulation;
}
table {
    border-collapse: collapse;
}
caption {
    padding-top: 0.75em;
    padding-bottom: 0.3em;
    text-align: left;
    caption-side: bottom;
}
input,
button,
select,
optgroup,
textarea {
    margin: 0;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
}
button,
input {
    overflow: visible;
}
button,
select {
    text-transform: none;
}
button,
html [type='button'],
[type='reset'],
[type='submit'] {
    -webkit-appearance: button;
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
    padding: 0;
    border-style: none;
}
input[type='radio'],
input[type='checkbox'] {
    box-sizing: border-box;
    padding: 0;
}
input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
    -webkit-appearance: listbox;
}
textarea {
    overflow: auto;
    resize: vertical;
}
fieldset {
    min-width: 0;
    margin: 0;
    padding: 0;
    border: 0;
}
legend {
    display: block;
    width: 100%;
    max-width: 100%;
    margin-bottom: 0.5em;
    padding: 0;
    color: inherit;
    font-size: 1.5em;
    line-height: inherit;
    white-space: normal;
}
progress {
    vertical-align: baseline;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
    height: auto;
}
[type='search'] {
    outline-offset: -2px;
    -webkit-appearance: none;
}
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
}
::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}
output {
    display: inline-block;
}
summary {
    display: list-item;
}
template {
    display: none;
}
[hidden] {
    display: none !important;
}
mark {
    padding: 0.2em;
    background-color: #feffe6;
}

/* ant design reset.css - end */

h1 {
    font-size: 28px;
}

h2 {
    margin-top: 1em;
    margin-bottom: 0.5em;
    font-weight: 500;
}

.ant-layout {
    background: #e9ebf3;
}

.ant-form {
    background: #fff;
    padding: 24px 24px 12px;
    margin-bottom: 24px;
}

/* floating strankovani */

.ant-table-wrapper .ant-pagination:first-child {
    position: absolute;
    top: -54px;
    right: 0;
}

/*
.ant-spin-container .ant-table-pagination:first-child {
    display: none;
}

.ant-spin-container .ant-table-pagination:last-child {
    z-index: 500;
    position: fixed;
    bottom: 0px;
    right: 0px;
    background: #fff;
    padding: 10px;
    margin: 0;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}
*/

/* floating strankovani - konec */

.btn-seamless,
.btn-seamless:hover,
.btn-seamless:active,
.btn-seamless:focus,
.btn-seamless:visited,
.btn-seamless .anticon-more {
    border: none !important;
    color: #434656 !important;
    opacity: 0.6 !important;
    box-shadow: none !important;
    background: transparent !important;
}

.btn-seamless:hover,
.btn-seamless:active,
.btn-seamless:focus,
.btn-seamless:visited,
.btn-seamless .anticon-more:hover {
    opacity: 1 !important;
}

.ant-btn[disabled].btn-seamless {
    background: none !important;
}

.ant-layout-sider {

}

.ant-descriptions {
    margin-bottom: 30px;
}

.ant-descriptions .ant-descriptions-header {
    background-color: #e9ebf3;
    margin-bottom: 0;
    padding-bottom: 8px;
}

/* Query Builder */

.queryBuilder .rule-value {
    flex: 1;
}

.queryBuilder .rule.queryBuilder-invalid .rule-value {
    border: 1px solid red;
}

.queryBuilder .ruleGroup-combinators .ant-select {
    width: auto;
}

.queryBuilder .ruleGroup {
    padding: 1em;
    gap: 1em;
}

.collapse-css-transition {
    transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

nav + h1, nav + div > div.ant-spin-container > h1 {
    margin-top: 0.25em;
}

.ant-card + .ant-card {
    margin-top: 1em;
}

.json-diff-viewer {
    table-layout: auto;
}

.ant-table-expanded-row > td {
    border-left: 24px solid #E7E9F3 !important;
    border-bottom: 16px solid #E7E9F3 !important;
}

.ant-table-expanded-row > td .ant-table-expanded-row > td {
    border: none !important;
}

textarea {
    border-radius: 2px !important;
}

th.ant-table-cell {
    font-weight: normal !important;
}

.ant-table-column-title {
    flex: initial !important;
}

.ant-table-column-sorter {
    flex: 1;
    margin-inline-start: 8px !important;
}

.ant-table-column-sorter .anticon {
    font-size: 15px;
}

th.ant-table-cell::before {
    width: 0 !important;
}

.ant-table-column-sorter {
    color: #bfbfbf !important;
}
