.json-diff-viewer {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  width: 100%;
}
.json-diff-viewer tr {
  vertical-align: top;
}
.json-diff-viewer tr .line-add {
  background: #a5d6a7;
}
.json-diff-viewer tr .line-remove {
  background: #ef9a9a;
}
.json-diff-viewer tr .line-modify {
  background: #ffe082;
}
.json-diff-viewer tr:hover td {
  position: relative;
}
.json-diff-viewer tr:hover td::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  content: '';
  pointer-events: none;
}
.json-diff-viewer tr.expand-line {
  text-align: center;
}
.json-diff-viewer tr.expand-line td {
  padding: 4px 0;
}
.json-diff-viewer tr.expand-line:hover td:before {
  background: transparent;
}
.json-diff-viewer tr.expand-line .has-lines-before {
  border-bottom: 1px solid;
}
.json-diff-viewer tr.expand-line .has-lines-after {
  border-top: 1px solid;
}
.json-diff-viewer tr.expand-line button {
  margin: 0 0.5em;
  padding: 0;
  color: #2196f3;
  font-family: sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  font-size: 12px;
  border: none;
  background: transparent;
  user-select: none;
  cursor: pointer;
}
.json-diff-viewer tr.expand-line button:hover {
  text-decoration: underline;
}
.json-diff-viewer td {
  padding: 1px;
  font-size: 0;
}
.json-diff-viewer td.line-number {
  border-right: 1px solid;
  box-sizing: content-box;
  font-family: monospace;
  font-size: 14px;
  padding: 0 8px;
  text-align: right;
  user-select: none;
}
.json-diff-viewer pre {
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  overflow: hidden;
  white-space: pre-wrap;
  word-break: break-all;
}
.json-diff-viewer pre .inline-diff-add {
  background: rgba(0, 0, 0, 0.08);
  text-decoration: underline;
  word-break: break-all;
}
.json-diff-viewer pre .inline-diff-remove {
  background: rgba(0, 0, 0, 0.08);
  text-decoration: line-through;
  word-break: break-all;
}
.json-diff-viewer-virtual pre {
  white-space: pre;
  overflow-x: auto;
}
.json-diff-viewer-virtual pre::-webkit-scrollbar {
  display: none;
}
